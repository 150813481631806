import UUID from 'uuidjs';
import {loginThird, getUserInfo, wxOpenId} from '../service/service';
import {getURLParame} from '@/util/util';
import {getWXCode} from '../service/wx_service';
import {getHomeData} from '@/service/service';
// import {getAliCode, getAliId} from '../service/ali_service';

export const guard = function(router, Vue) {
    router.beforeEach(async (to, from, next) => {
        const ua = window.navigator.userAgent;
        let organ_id = getURLParame('organ_id');
        const appid = getURLParame('appid');
        const school = getURLParame('organ_name');
        let code = getURLParame('code');
        let id = getURLParame('id');
        let href = window.location.href;
        console.log('guard appid', appid=='');
        console.log('guard href', href)
        if (organ_id !=undefined && organ_id !== localStorage.getItem('organ')) {
            localStorage.clear()
        }
        if (appid !=undefined && appid !== localStorage.getItem('appid')) {
            localStorage.removeItem('openid');
            localStorage.removeItem('appid');
            localStorage.removeItem('code');
        }

        if (appid && !code) {
            localStorage.setItem('href', href);
        }
        school && localStorage.setItem('school', decodeURIComponent(school));
        appid && localStorage.setItem('appid', appid);
        organ_id && localStorage.setItem('organ', organ_id);
        // Vue.prototype.$isAli = true;
        // 微信登录
        if (ua.indexOf('MicroMessenger') !== -1) {
            Vue.prototype.$isWX = true;
        }
        // 支付宝登录
        if (ua.indexOf('AlipayClient') !== -1) {
            Vue.prototype.$isAli = true;
        }
        const token = localStorage.getItem('token');
        if (to.meta.noToken) {
            next();
            return;
        }
        if (token && !Vue.prototype.$isWX && !Vue.prototype.$isWX) {
            const userInfo = localStorage.getItem('userInfo')
            !userInfo && getUserInfoFn()
            next();
            return;
        }
        if (!token && from.path === '/login') {
            next('/login');
            return;
        }
        if (Vue.prototype.$isAli) {
            let code = localStorage.getItem('code');
            if (!code) {
                const uuid = UUID.parse(UUID.generate());
                code = uuid.hexNoDelim;
            }
            loginThirdFn(code, next, Vue);
        } else if (Vue.prototype.$isWX) {
            let openId = localStorage.getItem('openid');
            // if (openId) {
            //     loginThirdFn(openId, next, Vue);
            //     return;
            // }
            if (!openId) {
                if (!code) {
                    if (appid!= undefined && appid != '') {
                        getWXCode(href, appid);
                    } else {
                        const uuid = UUID.parse(UUID.generate());
                        code = uuid.hexNoDelim;
                        loginThirdFn(code, next, Vue);
                    }
                    return
                } else {
                    if (id !=undefined  & organ_id == undefined) {
                        console.log('guard come');
                        let homeData
                        await getHomeData({
                            id: id
                        }).then(data=>{
                            console.log('getHomeData11',data);
                            homeData = data.data;
                        });
                        if (homeData) {
                            organ_id =  homeData.manageDTO.orgId;
                            console.log('getHomeData after',organ_id);
                            if (appid!= undefined && appid != '') {
                                await wxOpenId({
                                    organ_id,
                                    code
                                }).then(res => {
                                    openId = res.data;
                                    localStorage.setItem('openid', openId)
                                    // loginThirdFn(openId, next, Vue);
                                }).catch(err => {
                                    Vue.prototype.$toast.error(err.msg || '请求失败')
                                });
                            }
                            
                        }
                        console.log('getHomeData after','data');
                    } else {
                        if (appid!= undefined && appid != '') {
                            await wxOpenId({
                                organ_id,
                                code
                            }).then(res => {
                                openId = res.data;
                                localStorage.setItem('openid', openId)
                                // loginThirdFn(openId, next, Vue);
                            }).catch(err => {
                                Vue.prototype.$toast.error(err.msg || '请求失败')
                            });
                        }
                        
                    }

                }
            }
            let loginCode = localStorage.getItem('code');
            if (!loginCode) {
                const uuid = UUID.parse(UUID.generate());
                loginCode = uuid.hexNoDelim;
            }
            loginThirdFn(loginCode, next, Vue);
        } else {
            // encodeURIComponent(href)
            next('/login?nex='+ encodeURIComponent(href));
        }
    })
}

function loginThirdFn(id, next, Vue) {
    loginThird({
        'thirdPlatformType': 'wechat',
        'code': id
    }).then(res => {
        localStorage.setItem('code', id)
        localStorage.setItem('token', res.data)
        getUserInfoFn();
        next();
    }).catch((err) => {
        Vue.prototype.$toast.error(err.msg || '请求失败')
        next('/login');
    })
}

function getUserInfoFn() {
    getUserInfo().then(res => {
        res.data.userNameHide = res.data.realName.substring(0, 8) + '...';
        localStorage.setItem('userInfo', JSON.stringify(res.data));
    }).catch(err => {
        console.log(err);
    })
}
