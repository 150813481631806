import Loading from './loading.vue';

const loading = {
    install(Vue) {
        let vm;
        let loadingWrapper;
        const el = Vue.extend({
            render(h) {
                return h(Loading, {})
            },
            data: function () {
              return {
                type: '',
                msg: ''
              }
            }
        })
        function openLoading() {
            if (vm) return;
            vm = new el();
            loadingWrapper = document.createElement('div');
            const loadingEl = document.createElement('div');
            loadingEl.id = 'simple-loading-el';
            loadingWrapper.appendChild(loadingEl);
            document.body.appendChild(loadingWrapper);
            vm.$mount('#simple-loading-el');
        }
        function closeLoading() {
            if (vm) {
                vm.$destroy();
                vm = undefined;
            }
            if (loadingWrapper) {
                document.body.removeChild(loadingWrapper);
                loadingWrapper = undefined;
            }
        }
        Vue.prototype.$loading = {
            show() {
                openLoading();
            },
            hide() {
                closeLoading();
            }
        }
    }
}

export default loading;