const base_url_user = process.env.VUE_APP_BASE_URL_USER;
const base_url_bus = process.env.VUE_APP_BASE_URL_BUS;

// 8383
const user_api = {
    login: '/api/login/authenticate',
    loginThird: '/api/login/third_platform',
    setUserInfo: '/api/user/user_info',
    getUserInfo: '/api/user/info',
    getPasswordMsg: '/api/user/password/message',
    resetPassword: '/api/user/password/reset',
    changePassword: '/api/user/password/modify',
    getInfoMessage: '/api/user/user_info_message',
    getAliId: 'getAliId',
    getScholls: '/api/organization/org_list',
    getClasses: '/api/organization/org_tree',
    sendLoginMessage: '/api/user/mobileLogin/message',
    phoneLogin: '/api/login/mobile',
    homeData: '/api/mobile_manage/getAllMenu',
}
// 8585
const base_api = {
    getPayOrders: '/pay/order/orderlist',
    getPayDoneOrders: '/pay/order/success/payorderlist',
    aliPay: '/pay/webpay',
    wxPay: '/pay/wechatpay',
    ylPay: '/wy_pay/yx_pay_do',
    allPayMethod: '/merchant/method/all',
    transferSave: '/transferpayment/transfer/transferordersave',
    getRate: '/merchant/finddetail',
    wxOpenId: '/pay/getopenid',
    hasOrderUnpay: '/transferpayment/transfer/user_order_unpaid',
    getProjectList: '/acceptpayment/getProjectList',
    getProjectListByOrg: '/acceptpayment/getProjectListByOrg',
    payRemark: '/transferpayment/pay_remark',
}
let api = {}
for (const key in user_api) {
    if (Object.hasOwnProperty.call(user_api, key)) {
        const element = user_api[key];
        api[key] = base_url_user + element;
    }
}
for (const key in base_api) {
    if (Object.hasOwnProperty.call(base_api, key)) {
        const element = base_api[key];
        api[key] = base_url_bus + element;
    }
}

export default api;
