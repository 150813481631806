export function getMobileHide(mobile) {
    const reg = /^(\d{3})\d{4}(\d{4})$/

    return mobile.replace(reg, '$1****$2')
}

export function getCardHide(card) {
    const reg = /^(\d{6})\d{8}([0-9xX]{4})$/

    return card.replace(reg, '$1********$2')
}

export function getURLParame(key) {
    const url = window.location.search;
    const query = new Object();
    if (url.indexOf("?") != -1) {
        const urlArr = url.split('?')[1].split('&');
        urlArr.forEach(item => {
            const itemArr = item.split('=');
            query[itemArr[0]] = itemArr[1];
        });
    }
    return query[key];
}
export function overTime() {
    const now = new Date()
    const h = now.getHours()
    const m = now.getMinutes()
    if ((h === 0 && m <= 5) || (h === 23 && m >= 55)) {
        return true
    } else {
        return false
    }
}