import Vue from 'vue'
import VueRouter from 'vue-router'
import {guard} from './guard'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/home',
    component: () => import('@/views/Index/Index.vue'),
    children: [
      // {
      //   path: '/home',
      //   name: 'home',
      //   meta: {
      //     title: '查询账单',
      //     noBack: true
      //   },
      //   component: () => import('@/views/Bill/Query.vue')
      // },
      {
        path: '/home',
        name: 'home',
        meta: {
          title: '校园服务',
          noBack: true
        },
        component: () => import('@/views/Home/index.vue')
      },
      {
        path: '/me',
        name: 'me',
        meta: {
          title: '我的',
          noBack: true
        },
        component: () => import('@/views/Me/Index.vue')
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      noHeader: true,
      noToken: true
    },
    component: () => import('@/views/Login/Login.vue')
  },
  {
    path: '/bill',
    name: 'bill',
    meta: {
      title: '账单缴费'
    },
    component: () => import('@/views/Bill/Bill.vue')
  },
  {
    path: '/index',
    name: 'index',
    meta: {
      title: '查询账单'
    },
    component: () => import('@/views/Bill/Query.vue')
  },
  {
    path: '/find_bill',
    name: 'find_bill',
    meta: {
      title: '查询账单'
    },
    component: () => import('@/views/Bill/Query.vue')
  },
  {
    path: '/changePassword',
    name: 'changePassword',
    meta: {
      title: '修改密码'
    },
    component: () => import('@/views/Me/ChangePassword.vue')
  },
  {
    path: '/forgetPassword',
    name: 'forgetPassword',
    meta: {
      title: '重置密码',
      noToken: true
    },
    component: () => import('@/views/Me/ForgetPassword.vue')
  },
  {
    path: '/userInfo',
    name: 'userInfo',
    meta: {
      title: '个人信息',
    },
    component: () => import('@/views/Me/UserInfo.vue')
  },
  {
    path: '/transferList',
    name: 'transferList',
    meta: {
      title: '转账项目',
      noBack: true
    },
    component: () => import('@/views/Transfer/TransferItem.vue')
  },
  {
    path: '/transfer_List',
    name: 'transfer_List',
    meta: {
      title: '转账项目',
      noBack: true
    },
    component: () => import('@/views/Transfer/Transfer_Item.vue')
  },
  {
    path: '/transferItem',
    name: 'transferItem',
    meta: {
      title: '转账支付'
    },
    component: () => import('@/views/Transfer/TransferItem.vue')
  },
  {
    path: '/transferConfirm',
    name: 'transferConfirm',
    meta: {
      title: '订单确认'
    },
    component: () => import('@/views/Transfer/TransferConfirm.vue')
  },
  {
    path: '/billConfirm',
    name: 'billConfirm',
    meta: {
      title: '账单确认'
    },
    component: () => import('@/views/Bill/BillConfirm.vue')
  },
  {
    path: '/paySuccess',
    name: 'paySuccess',
    meta: {
      title: '支付成功',
      noToken: true,
      noBack: true
    },
    component: () => import('@/views/Pay/PaySuccess.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

guard(router, Vue);

export default router
