<template>
    <v-overlay>
        <v-progress-circular
            indeterminate
            size="48"
        ></v-progress-circular>
    </v-overlay>
</template>

<script>
    export default {
        name: 'Loading',
        data() {
            return {
                
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>