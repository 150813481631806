<template>
    <div class="layout-header primary">
        <div class="header-back" v-if="!noBack" @click="back">
            <v-icon x-large>mdi-chevron-left</v-icon>
        </div>
        {{title}}
    </div>
</template>

<script>
export default {
    name: 'Header',
    data() {
        return {

        }
    },
    computed: {
        title() {
            return this.$route.meta.title;
        },
        noBack() {
            return !!this.$route.meta.noBack;
        }
    },

    methods: {
        back() {
            this.$router.go(-1);
        }
    }
}
</script>

<style lang="scss" scoped>
.layout-header {
    height: 56px;
    line-height: 56px;
    text-align: center;
    padding: 0 50px;
    position: relative;
    font-weight: bold;
    font-size: 18px;
    .header-back {
        height: 100%;
        width: 50px;
        position: absolute;
        left: 0;
    }
}
</style>
