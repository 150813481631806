<template>
    <v-app class="app-wrapper">
        <Header class="app-header" v-if="!noHeader" />
        <div class="app-content" :class="{height100: noHeader}">
          <router-view  :key="$route.fullPath" />
        </div>
    </v-app>
</template>

<script>
import Header from './views/Layout/Header';
import {getURLParame} from '@/util/util';

export default {
  name: 'App',

  components: {
    Header,
  },

  data: () => ({
    //
  }),

  computed: {
    noHeader() {
      return !!this.$route.meta.noHeader
    }
  },

  beforeMount() {
    const school = getURLParame('school');
    document.title = school ? decodeURIComponent(school) : '智慧校园';
  }
};
</script>

<style lang="scss" scoped>
.app-wrapper {
  height: 100%;
  .app-content {
    height: calc(100% - 56px);
    overflow-y: auto;
    &.height100 {
      height: 100%;
    }
  }
}
</style>
