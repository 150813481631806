import request from '../util/request';
import api from './api';

// 登录
export async function login(params) {
    return request(api.login, 'POST', params, true);
}

// 获取用户信息
export async function getUserInfo(params) {
    return request(api.getUserInfo, 'GET', params);
}


export async function sendLoginMessage(params) {
    return request(api.sendLoginMessage, 'POST', params);
}

export async function phoneLogin(params) {
    return request(api.phoneLogin, 'POST', params);
}

export async function getHomeData(params) {
    return request(api.homeData, 'POST', params);
}

export async function getHomeData2(params) {
    return request(api.homeData, 'POST', params);
}

// 忘记密码获取短信验证码
export async function getPasswordMsg(params) {
    return request(api.getPasswordMsg, 'POST', params, true);
}

// 重置密码
export async function resetPassword(params) {
    return request(api.resetPassword, 'POST', params, true);
}

// 修改密码
export async function changePassword(params) {
    return request(api.changePassword, 'POST', params);
}

// 获取订单列表
export async function getPayOrders(params) {
    return request(api.getPayOrders, 'GET', params);
}

// 获取已完成订单列表
export async function getPayDoneOrders(params) {
    return request(api.getPayDoneOrders, 'GET', params);
}

// 支付宝支付
export async function aliPay(params) {
    return request(api.aliPay, 'GET', params);
}

// 微信支付
export async function wxPay(params) {
    return request(api.wxPay, 'GET', params);
}

// 银联支付
export async function ylPay(params) {
    return request(api.ylPay, 'POST', params);
}

export async function allPayMethod(params) {
    return request(api.allPayMethod, 'POST', params);
}
// 微信支付
export async function payRemark(params) {
    return request(api.payRemark, 'POST', params);
}

// 第三方登录
export async function loginThird(params) {
    return request(api.loginThird, 'POST', params, true);
}

// 完善用户信息
export async function setUserInfo(params) {
    return request(api.setUserInfo, 'PUT', params);
}

// 完善信息获取短信验证码
export async function getInfoMessage(params) {
    return request(api.getInfoMessage, 'POST', params);
}

// 获取学校列表
export async function getScholls(params) {
    return request(api.getScholls, 'URL', params);
}

// 获取班级列表
export async function getClasses(params) {
    return request(api.getClasses, 'URL', params);
}

// 保存转账付款信息
export async function transferSave(params) {
    return request(api.transferSave, 'POST', params);
}

// 获取转账手续费费率
export async function getRate(params) {
    return request(api.getRate, 'POST', params);
}

// 获取微信id
export async function wxOpenId(params) {
    return request(api.wxOpenId, 'GET', params);
}

export async function hasOrderUnpay(params) {
    return request(api.hasOrderUnpay, 'POST', params);
}

export async function getProjectList(params) {
    return request(api.getProjectList + '/' + params, 'GET');
}

export async function getProjectListByOrg(params) {
    return request(api.getProjectListByOrg, 'GET', params);
}
