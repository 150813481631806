import axios from 'axios';

export default function request(url, method, params, noToken) {
  const option = {
    url: url,
    method: method === 'URL' ? 'GET' : method,
  }
  if (method === 'GET') {
    option.params = params
  }
  if (method === 'POST' || method === 'PUT') {
    option.data = params
  }
  if (method === 'URL') {
    option.url = option.url + '/' + params
  }
  if (!noToken) {
    const token = localStorage.getItem('token');
    option.headers = {
      'Authorization': token
    }
  }
  return new Promise((resolve, reject) => {
    axios(option).then(res => {
      const data = res.data;
      if (data.code !== 1 && data.code !== 200) {
        reject(data)
      } else {
        resolve(data)
      }
    }).catch(err => {
      reject(err);
    })
  });
}