import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import './theme/style.scss'
import toast from '@/components/toast'
import loading from '@/components/loading'
import VueScroller from 'vue-scroller'

Vue.use(VueScroller)
Vue.use(toast);
Vue.use(loading);

Vue.config.productionTip = false

Vue.prototype.$isWX = false;
Vue.prototype.$isAli = false;
Vue.prototype.$schoolName = '';

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
